.brick {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
  background-repeat: no-repeat  !important;
      background-position: 50% 50%  !important;
}



.wrapper-container2 span.screen {
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:3000px;
	height:3000px;
	background:transparent url(https://matthewjamestaylor.com/blog/crt-screen.gif) top left repeat;
}
.wrapper-container2 span.top {
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:3000px;
	height:70px;
	background:transparent url(https://matthewjamestaylor.com/blog/crt-top.png) top left repeat-x;
}
.wrapper-container2 span.bottom {
	display:block;
	position:absolute;
	left:0;
	bottom:0;
	width:3000px;
	height:110px;
	background:transparent url(https://matthewjamestaylor.com/blog/crt-bottom.png) bottom left repeat-x;
}

.title-container {

  position: absolute;
  font-family: 'Product Sans',Arial,Helvetica,sans-serif;
  text-shadow: 0 1px 0 rgba(0,0,0,0.2);
  right: 0px;
  width: 90%;
  padding: 20px;
  padding-left: 40px;
  border-left: 1px solid;
  padding-bottom: 60px;
  bottom:0px;
  font-weight: bold; 
  font-size: 23px;
  color: #fff;
}
.title {

  
  box-decoration-break: clone;
  line-height: 1.8;



  

  
  }

  .header {
    color: rgba(255, 255, 255, 0.7)
  }

  .floating-top-right {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .index {

    margin-top: 35px;
    color: #fff;
    background: red;
    padding: 5px;
    border-radius: 2px;
    font-family: 'Product Sans',Arial,Helvetica,sans-serif;
    text-shadow: 0 1px 0 rgba(0,0,0,0.2);
    font-size: 18px;
    opacity: 0.6;
    text-align: center;

  }

  .category {
   
    font-size: 15px;
  }

  .wrapper-container2 .time {

    position: absolute;
    top: 10px;
    right: 0px;
    width: 130px;
    text-align: center;
    }