.stats-background {
            
            
}
.stats-background .item {
            background: #fff;
            padding: 5px;
            margin-bottom:2px;
            border-radius: 3px;
}

.stats-background .label {
            margin-bottom: 3px;
}