.dashboard, .wall-selected-true {
  height: 100%;
}

.filters {
  display:inline-block;
  text-shadow: 0 1px 0 rgba(0,0,0,0.2);
  font-size: 12px;
  color: #fff;
  padding-left: 15px;
  
}
.filters .selected{
  border-bottom: thin dotted;
  font-weight: bold;
  
}

.overlay-left-bottom {
  position: absolute;
  bottom: 15px;
  left: 15px;
}