
@-webkit-keyframes blink {
    0% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    50.01% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}

@keyframes blink {
    0% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    50.01% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}

  
  .Typist .Cursor {
    display: inline-block;
    margin-left: 3px;
    text-shadow: 0 0.025em 0 rgba(0,0,0,0.1);
    word-wrap: break-word;

  }
  
  .rotation-false .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 0.6s linear infinite;

  }

  
  .container {

    position: relative;
    display: inline-block;
    display: table;
    float: left;
    
        font-family: 'Product Sans',Arial,Helvetica,sans-serif;
    text-shadow: 0 1px 0 rgba(0,0,0,0.2);
    
    
    color: #fff;
    
    font-size: 23px;
    font-weight: bold; 
    padding2: 10px;
    transition: background-color 0.4s;
    overflow: hidden;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    background-repeat: no-repeat  !important;
        background-position: 50% 50%  !important;
    }


.wrapper-container {

position: relative;
display: inline-block;
display: table;
float: left;

    font-family: 'Product Sans',Arial,Helvetica,sans-serif;
text-shadow: 0 1px 0 rgba(0,0,0,0.2);


color: #fff;

font-size: 23px;
font-weight: bold; 
padding2: 10px;
transition: background-color 0.4s;
overflow: hidden;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover !important;
background-repeat: no-repeat  !important;
    background-position: 50% 50%  !important;
}

.top-image {
background-size: cover !important;
opacity: 0.95;
}


.typing-wrapper-container{
position: relative;
display: inline-block;
padding: 25px;
margin-left2:50% !important;
margin-top2: 40% !important;
border-left2: solid 1px #fff;
padding-bottom2: 50px;
}
.my-custom-class {
    
box-shadow: 0 0 0 10px #29a8ab;
background-color2: #29a8ab;
box-decoration-break: clone;
line-height: 1.8;

}

.Cursor-blinking {
    padding-right: 10px;
}

.container-right {

    font-size: 14px;
    position: absolute;
    top: 15px;
    opacity: 0.7;
    right: 15px;
    
    }
.header {

font-size: 14px;
margin-left:15px;
position: absolute;
top: 15px;
opacity: 0.7;

}

.story-rank2 {
display: inline-block;
font-size: 12px;
padding: 2px;
padding-top: 4px;
background: rgba(255, 255, 255, 0.5);
border-radius: 2px;
text-shadow: none;
}


.semitransparent {
display: inline-block;
transition: 0.3s;
text-shadow: none;
font-size: 12px;


padding: 2px;
padding-top: 4px;
background: rgba(255, 255, 255, 0.45);
border-radius: 2px;
margin-left: 1px;

}
.interactions {
transition: 0.5s;
position: absolute;
bottom: 8px;
right: 15px;
z-index: 100000;

}

.interactions  .semitransparent {
width: 40px;
margin2: auto;
text-align: center;
}

.time {

    position: absolute;
    top: 15px;
    right: 15px;
    padding:6px;
    }

.rotation-true .time.standard {
    right: -200px;
}
.time.standard2 {
    font-size: 20px;
    padding: 5px;
}
.qr-container{
    transition: 0.5s;
    position: absolute;
    bottom:15px;
    left: 15px;
    opacity: 0.5;
    text-align: center;
    font-size: 16px;
}

.qr {


width: 60px ;
height: 60px;
padding: 3px;
border-radius: 4px;
background2: #000;
border2: solid 2px #00000094;
border2: solid 2px #000;
box-shadow2: 0 1px 0 rgba(0,0,0,0.2);


}
.rotation-true .interactions{

    bottom: -40px;
}
.wrapper-container:hover  .qr-container {
opacity: 1;

}
.wrapper-container:hover  .time {
background: #fff;
top: 15px;
right: 15px;

}

.wrapper-container:hover  .interactions {
background: #fff;
bottom: 40px !important;
opacity: 1;
border-radius: 2px;
}

.rotation-true   .semitransparent {
background: rgba(255, 255, 255, 0);
transform: rotate3d(0, 1, 0, 720deg);
}
.rotation-true .time {
right: -40px;
}

.rotation-true .qr-container {
bottom: -540px;

}

/*
.first-time-false {
transition:0.1s;
}
.first-time-true {
top: -200px;
opacity: 0;
border-radius2: 50%;
}
*/
a, a:hover{
text-decoration: none;
color: #fff;
}

.background-image {
    background2: linear-gradient(to top,rgba(0,0,0,.54) 40%,rgba(0,0,0,0));
    background2: rgba(255, 0, 0, 0.2);

vertical-align:bottom;
display: table-cell;
}


.wrapper-container span.screen {
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:3000px;
	height:3000px;
	background:transparent url(https://matthewjamestaylor.com/blog/crt-screen.gif) top left repeat;
}
.wrapper-container span.top {
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:3000px;
	height:70px;
	background:transparent url(https://matthewjamestaylor.com/blog/crt-top.png) top left repeat-x;
}
.wrapper-container span.bottom {
	display:block;
	position:absolute;
	left:0;
	bottom:0;
	width:3000px;
	height:110px;
	background:transparent url(https://matthewjamestaylor.com/blog/crt-bottom.png) bottom left repeat-x;
}


.platform-content-image {
    -webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover !important;
margin-bottom: 2px;
border-radius: 2px;
padding: 5px;
}

.container-platforms {

    display: inline-block
}
.selected-avaiable-true {
    transition: 0.5s;
}
.selected-avaiable-true.selected-avaiable-tension-1{
    opacity: 0.7;
    
}
.selected-avaiable-true.selected-avaiable-tension-2{
    opacity: 0.5;
}
.selected-avaiable-true.selected-avaiable-tension-3{
    opacity: 0.3;
    transition: 1s;
}
.selected-avaiable-true.selected-avaiable-tension-4, .selected-avaiable-true.selected-avaiable-tension-5{
    opacity: 0.2;
    transition: 1s;
}
.selected-avaiable-true2 {

    transition: 1.2s;
    border2: solid 5px red;
    opacity: 0.3;
    -webkit-filter2: grayscale(100%); /* Safari */
    filter2: grayscale(100%);
    transform2: scaleY(0) scaleX(0);
    transform-style2: preserve-3d;
    transform-origin2: center right;
    transform2: rotateX( 180deg );
}

.selected-avaiable-true2.selected-avaiable-direction-1 {
    transform2: rotateX( -180deg );
    transform2: scaleY(3.8) scaleX(3.8);
}