/* TABLE MATRIX SELECTOR */

.table-cell-selector {

            width: 30px;
            height: 30px;
            background: rgba(255, 255, 255, 0.4);
            border: solid 1px rgba(255, 255, 255, 0.9);
            border-radius2: 2px;
          }
          
          
          .table-cell-selector.selected-true{
            
            background: rgb(243, 243, 243);
          }
          
          
          .table-cell-selector.hover-true{
            background: rgba(255, 255, 255, 0.7);
          }
          
          .icon-table {
            color: #fff;
            text-shadow: 0 0.025em 0 rgba(0,0,0,0.1);
          }