html, body, #root, .wall{
  height: 100%;
  font-family: 'Roboto',Arial,sans-serif;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  perspective: 800px;
  -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
line-height: unset;
}



.App{
  color: #fff;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}